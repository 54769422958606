import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import { KeyboardEvent, useEffect, useMemo } from "react";
import ValidationError from "../utilities/ValidationError";

type TVariant = "underline" | undefined;

type Props = {
  label?: string;
  spacing?: string;
  required?: boolean;
  className?: string;
  variant?: TVariant;
  submitOnEnter?: boolean;
  onChange?: (value: string) => void;
  prefix?: string;
  suffix?: string;
  textSizeClassName?: string;
  additionalInformation?: string;
  min?: string;
  max?: string;
};

// TODO Remove spacing from inside of component
const NumberInput = ({
  label,
  spacing,
  required = false,
  className,
  variant = "underline",
  submitOnEnter = true,
  onChange,
  prefix,
  suffix,
  textSizeClassName = "text-base",
  additionalInformation,
  min = "0",
  max = "9999",
  ...props
}: Props & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const { value } = meta;

  const styling = useMemo(() => (variant === "underline" ? "input-underline" : "input-field"), [variant]);
  const inputStyle = useMemo(() => (meta.touched && meta.error ? `${styling} error` : styling), [meta, styling]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  // TODO: Fix keyDown
  return (
    <div className={spacing || "mb-5"}>
      {label && (
        <label htmlFor={props.id || props.name} className={"text-sm"}>
          {label} {required && <>*</>}
        </label>
      )}
      <div className="relative flex border-b border-b-gray-400 focus-within:border-b-red-900">
        {prefix && (
          <span
            className={classNames(inputStyle, textSizeClassName, "!w-[unset] !pr-1.5")}
            style={{ borderBottomStyle: "none" }}
          >
            {prefix}
          </span>
        )}
        <input
          type="number"
          className={classNames(inputStyle, className, textSizeClassName)}
          style={{ borderBottomStyle: "none" }}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (
              !/[,0-9.]/.test(event.key) &&
              event.key !== "Backspace" &&
              event.key !== "Tab" &&
              !event.metaKey &&
              !event.ctrlKey &&
              event.key !== "ArrowUp" &&
              event.key !== "ArrowDown" &&
              event.key !== "ArrowLeft" &&
              event.key !== "ArrowRight" &&
              (submitOnEnter ? event.key !== "Enter" : true)
            ) {
              event.preventDefault();
            }
          }}
          autoComplete="off"
          min={min}
          max={max}
          {...field}
          {...props}
        />
        {suffix && (
          <span className={classNames(inputStyle, "!w-[unset] !pl-1.5")} style={{ borderBottomStyle: "none" }}>
            {suffix}
          </span>
        )}
      </div>

      {additionalInformation && <p className="mt-1 text-xs text-gray-700">{additionalInformation}</p>}

      <>
        {meta.touched && meta.error && <ValidationError className="mt-2" message={meta.error} hasBackground={false} />}
      </>
    </div>
  );
};

export default NumberInput;
