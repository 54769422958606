import { CategoryDto, CreateProductDto, UpdateProductDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import NumberInput from "components/basics/NumberInput";
import Textarea from "components/basics/Textarea";
import TextInput from "components/basics/TextInput";
import SelectCategoryPreview from "components/category/SelectCategoryPreview";
import Container from "components/layouts/Container";
import ContentMediaSelect from "components/molecules/ContentMediaSelect";
import ValidationError from "components/utilities/ValidationError";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Callout from "components/atoms/Callout";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import { useLocation } from "react-router-dom";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type TFormValues = {
  name: string;
  description: string;
  categories: CategoryDto[];
  netAmount: number;
};

type Props = {
  submit: (value: CreateProductDto) => Promise<void> | ((value: UpdateProductDto) => Promise<void>);
  isEditMode: boolean;
};

const EditProductData = ({ isEditMode, submit }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const navigate = useStatefulNavigate();
  const { pathname, state } = useLocation();
  const selectedMedia = (state?.selectedMedia ?? []) as TSelectedMedia[];

  let initialFormValues = {
    description: state?.data?.description ?? "",
    name: state?.data?.name ?? "",
    categories: state?.data?.categories ?? [],
    netAmount: state?.data?.netAmount ?? 0,
  };

  const [showNoMediaSelectedError, setShowNoMediaSelectedError] = useState(false);

  const [formValues, setFormValues] = useState<TFormValues>();

  const handleOnSubmit = async (values: TFormValues) => {
    // if no media is selected and not in edit mode show error
    if (!isEditMode && !selectedMedia.length) {
      setShowNoMediaSelectedError(true);
      return;
    }

    const tmpProduct = {
      ...values,
      price: values.netAmount,
      categories: values.categories?.map((c) => c._id),
      mediaIds: selectedMedia.map((m) => m._id),
      type: CreateProductDto.type.PHYSICAL_PRODUCT,
      kind: CreateProductDto.type.PHYSICAL_PRODUCT,
    };

    await submit(tmpProduct);
  };

  const handleRedirectToVault = () => {
    const data = {
      name: formValues?.name,
      description: formValues?.description,
      netAmount: formValues?.netAmount,
      categories: formValues?.categories,
    };
    navigate("/vault", { state: { redirectTo: pathname, data, allowMultipleSelect: false }, replace: true });
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .trim()
          .min(4, ({ min }) => t("validation.min", { min: min }))
          .max(50, ({ max }) => t("validation.max", { max: max }))
          .required(t("errorMessage.productTitle") || ""),
        description: Yup.string()
          .trim()
          .min(1, ({ min }) => t("validation.min", { min: min }))
          .max(600, ({ max }) => t("validation.max", { max: max }))
          .required(t("errorMessage.description") || ""),
        categories: Yup.array()
          .min(1, ({ min }) => t("errorMessage.minOneCategory", { min: min }))
          .max(3, ({ max }) => t("validation.selectMax", { max: max })),
        netAmount: Yup.number()
          .typeError(t("validation.onlyNumbers") ?? "")
          .min(1, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
          .max(9999, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
          .test("is-decimal", t("validation.only2Decimals") ?? "", (val) => {
            if (val !== undefined) {
              return /^\d+(\.\d{0,2})?$/.test(val.toString());
            }
            return false;
          })
          .required(t("validation.required") ?? ""),
      })}
      onSubmit={handleOnSubmit}
    >
      {({ values }: { values: TFormValues }) => {
        setFormValues(values);

        return (
          <Form className={"flex flex-1 flex-col pb-4"}>
            {!isEditMode && (
              <>
                <ContentMediaSelect
                  handleRedirectToVault={handleRedirectToVault}
                  processingMessage={t("processingStates.product") ?? ""}
                />
                {showNoMediaSelectedError && (
                  <div className="mx-auto w-full max-w-xl px-4 md:px-0">
                    <ValidationError className="mt-2" message={t("errorMessage.media")} hasBackground={false} />
                  </div>
                )}
              </>
            )}
            <Container hasPadding={false} noDesktopPadding className="mt-4 px-4 md:px-0">
              <Callout>{t("posts.productRestrictions")}</Callout>
              <div className={"mb-2 flex flex-1 flex-col md:flex-grow-0"}>
                <TextInput label={t("productHeading")} type="text" name="name" placeholder={t("productName") || ""} />

                <Textarea
                  label={t("productDescription")}
                  type="text"
                  name="description"
                  placeholder={t("describeProduct") || ""}
                  maxLength={2000}
                  className="mb-2"
                />

                <NumberInput
                  data-testid={"price-input"}
                  label={t("price") || ""}
                  name="netAmount"
                  prefix={"€"}
                  inputMode={"decimal"}
                  placeholder={t("price") || ""}
                  spacing={"mb-3"}
                  additionalInformation={t("priceInclShipping") || ""}
                />

                <SelectCategoryPreview name={"categories"} minCategories={1} maxCategories={3} type={"PRODUCT"} />
              </div>

              {!isEditMode && <span className={"mb-1 text-sm text-gray-500"}>{t("publishConsentProduct")}</span>}

              {isEditMode ? (
                <Button
                  variant={"primary"}
                  type={"submit"}
                  text={t("product.publishChanges")}
                  data-testid={"update-product-button"}
                />
              ) : (
                <Button variant={"primary"} type={"submit"} text={t("publish")} />
              )}
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProductData;
