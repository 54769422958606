import { ChatNavIcon, ChatNavIconSelected } from "components/atoms/icons/ChatNavIcon";
import { UserNavIcon, UserNavIconSelected } from "components/atoms/icons/UserNavIcon";
import {
  HiCloud,
  HiCreditCard,
  HiHome,
  HiListBullet,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineCloud,
  HiOutlineCog6Tooth,
  HiOutlineCreditCard,
  HiOutlineHome,
  HiOutlineListBullet,
  HiOutlineMagnifyingGlass,
  HiOutlinePlusCircle,
  HiOutlineShoppingBag,
  HiOutlineUsers,
  HiOutlineWallet,
  HiPlusCircle,
  HiShoppingBag,
} from "react-icons/hi2";
import { NavItemListType } from "types/navigation.types";

export const navigationLinks: NavItemListType[] = [
  {
    Icon: HiOutlineHome,
    SelectedIcon: HiHome,
    title: "home",
    type: "link",
    order: 1,
    to: "/",
    userRoles: ["fan", "creator"],
  },
  {
    Icon: HiOutlineMagnifyingGlass,
    SelectedIcon: HiOutlineMagnifyingGlass,
    title: "discovery",
    type: "link",
    order: 2,
    to: "/search",
    userRoles: ["fan", "creator"],
  },
  {
    Icon: HiOutlineShoppingBag,
    SelectedIcon: HiShoppingBag,
    title: "shop",
    type: "link",
    order: 3,
    to: "/shop",
    userRoles: ["fan"],
  },
  {
    Icon: ChatNavIcon,
    SelectedIcon: ChatNavIconSelected,
    title: "chat",
    type: "link",
    order: 4,
    to: "/chat",
    userRoles: ["fan", "creator"],
  },
  {
    Icon: HiOutlineListBullet,
    SelectedIcon: HiListBullet,
    title: "lists",
    type: "link",
    order: 4,
    to: "/lists",
    userRoles: ["creator"],
  },
  {
    Icon: HiOutlineCloud,
    SelectedIcon: HiCloud,
    title: "vault",
    type: "link",
    order: 5,
    to: "/vault",
    userRoles: ["creator"],
  },
  {
    Icon: HiOutlineCreditCard,
    SelectedIcon: HiCreditCard,
    title: "earnings",
    type: "link",
    order: 6,
    to: "/payout",
    userRoles: ["creator"],
  },
  {
    Icon: UserNavIcon,
    SelectedIcon: UserNavIconSelected,
    title: "account",
    type: "link",
    order: 7,
    to: "/creator/:username",
    userRoles: ["creator"],
  },
  {
    Icon: UserNavIcon,
    SelectedIcon: UserNavIconSelected,
    title: "account",
    type: "link",
    order: 8,
    to: "/profile",
    userRoles: ["fan"],
  },
];

export const verificationLink: NavItemListType = {
  Icon: HiOutlinePlusCircle,
  SelectedIcon: HiPlusCircle,
  title: "create",
  type: "link",
  order: 3,
  to: "/verification/start",
  userRoles: ["creator"],
};

export const morePopUpNavigationLinks: NavItemListType[] = [
  {
    title: "profileMenu.personalData",
    to: "/profile/data",
    Icon: HiOutlineCog6Tooth,
    userRoles: ["creator", "fan"],
    order: 1,
  },
  {
    title: "fans.title",
    to: "/fans",
    Icon: HiOutlineUsers,
    userRoles: ["creator"],
    order: 2,
  },
  {
    title: "subscription.name_plural",
    to: "/subscriptions",
    Icon: HiOutlineWallet,
    userRoles: ["creator", "fan"],
    order: 4,
  },
  {
    title: "chats.settings.title",
    to: "/chat/settings",
    Icon: HiOutlineChatBubbleOvalLeft,
    userRoles: ["creator"],
    order: 5,
  },
  {
    title: "order.name_plural",
    to: "/orders",
    Icon: HiOutlineShoppingBag,
    userRoles: ["creator", "fan"],
    order: 6,
  },
];
